import React from "react";

function ErrorNotFound() {
  return (
    <div className="h-screen w-full flex justify-center">
      <h3 className="rounded-lg text-3xl px-12 flex flex-col justify-center">
        Error 404: Page Not Found
      </h3>
    </div>
  );
}

export default ErrorNotFound;
